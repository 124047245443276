import { useQuery } from '@tanstack/react-query';
import getAssignedReferralsAndGroups from '../api/getAssignedReferralsAndGroups';

export const QUERY_KEY = 'ASSIGNED_REFERRALS_LIST';

const useGetAssignedReferralsAndGroups = (studyInstanceUid: string, enabled = false) => {
  const { data: assignedReferralsAndGroups, ...rest } = useQuery({
    queryKey: [QUERY_KEY, studyInstanceUid],
    queryFn: () => getAssignedReferralsAndGroups(studyInstanceUid),
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    cacheTime: 1000 * 60 * 60 * 12.5, // 12.5 hours
    retry: false,
    enabled,
  });

  return { assignedReferralsAndGroups, ...rest };
};

export default useGetAssignedReferralsAndGroups;

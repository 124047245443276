import { useQuery } from '@tanstack/react-query';
import getReferrersWithGroups, {
  GetReferrersWithGroupsFilters,
} from '../api/getReferrersWithGroups';

export const GET_REFERRERS_WITH_GROUPS_KEY = 'GET_REFERRERS_WITH_GROUPS_KEY';

const useGetReferrersWithGroups = (filters: GetReferrersWithGroupsFilters) => {
  const { data: response, ...rest } = useQuery({
    queryFn: () => getReferrersWithGroups(filters),
    onSuccess: () => {},
    queryKey: [GET_REFERRERS_WITH_GROUPS_KEY, filters.page, filters.pageSize, filters.searchTerm],
    cacheTime: 1000 * 60 * 60 * 1, // 1 hour
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
  });

  return { ...rest, response };
};

export default useGetReferrersWithGroups;

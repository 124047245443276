import React, { useState } from 'react';
import Input, { InputProps } from '@mui/material/Input';

export interface FolderInputProps extends InputProps {
  onNewFolderNameAccepted: (newFolderName: string) => void;
  initialValue?: string;
}

const FolderInput = ({ onNewFolderNameAccepted, initialValue, ...props }: FolderInputProps) => {
  const [newTemplateName, setNewTemplateName] = useState(initialValue || '');

  return (
    <Input
      value={newTemplateName}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
          e.preventDefault();
          onNewFolderNameAccepted(newTemplateName);
        }
      }}
      onChange={(e) => {
        setNewTemplateName(e.target.value);
      }}
      onBlur={() => {
        onNewFolderNameAccepted(newTemplateName);
      }}
      placeholder="Nuevo nombre"
      {...props}
    />
  );
};

export default FolderInput;

import React, { useState } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderIcon from '@mui/icons-material/Folder';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import ViewerDialog from '@components/ViewerDialog';
import { TemplateFile } from '@core/types';
import FolderView from 'app/features/templates/components/FolderView';
import useGetFolders from 'app/features/templates/hooks/useGetFolders';
import useSx from './sx';

interface FolderViewDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onTemplateClick?: (template: TemplateFile, templateFolderId: number | undefined) => void;
}

const SECTIONS = [
  { label: 'Organization', text: 'Organización', icon: ApartmentIcon },
  { label: 'Private', text: 'Privado', icon: FolderIcon },
];

const FolderViewDialog = ({ isOpen, onTemplateClick, onClose }: FolderViewDialogProps) => {
  const sx = useSx();

  const [filters, setFilters] = useState<{
    Section: 'Private' | 'Organization';
    SearchTerm?: string;
  }>({
    Section: 'Private',
  });

  const { folders, isLoading } = useGetFolders(filters, true);

  const onFileClickMediator = (template: TemplateFile) => {
    onTemplateClick?.(template, undefined);
  };

  const handleRoleChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: 'Organization' | 'Private',
  ) => {
    setFilters({ ...filters, Section: newValue });
  };

  return (
    <ViewerDialog sx={sx.root} open={isOpen} onClose={onClose} title="Elegir plantilla">
      <MuiTabs textColor="primary" onChange={handleRoleChange} value={filters.Section} sx={sx.tabs}>
        {SECTIONS.map(({ label, text, icon: Icon }) => (
          <Tab
            value={label}
            label={
              <>
                <Icon />
                <Typography>{text}</Typography>
              </>
            }
            key={label}
            sx={sx.tab}
          />
        ))}
      </MuiTabs>
      <Box sx={sx.card}>
        <FolderView
          itemArray={folders?.root || {}}
          isLoadingFolders={isLoading}
          onTemplateClick={onFileClickMediator}
          smallView
          enableLocalSearch
        />
      </Box>
    </ViewerDialog>
  );
};
export default FolderViewDialog;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';

dayjs.extend(utc);

function formatToSpanishDate(isoDateString: string, options?: { full: true }): string {
  const date = dayjs.utc(isoDateString).local().locale('es');

  if (options?.full) {
    return date.format('D MMMM YYYY, h:mm A');
  }

  return date.format('D MMMM YYYY');
}

export default formatToSpanishDate;

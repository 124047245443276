import { useMutation, useQueryClient } from '@tanstack/react-query';
import revokeReferralStudyAccess from '../api/revokeReferralStudyAccess';
import { QUERY_KEY as ASSIGNED_REFERRALS_LIST } from './useGetAssignedReferralsAndGroups';

export const QUERY_KEY = 'REVOKE_RADIOLOGIST';

const useRevokeReferralStudyAccess = () => {
  const queryClient = useQueryClient();
  const { isLoading: isUploading, mutateAsync: revokeReferralAccess } = useMutation({
    mutationFn: ({
      studyInstanceUid,
      identityId,
    }: {
      studyInstanceUid: string;
      identityId: string;
    }) => revokeReferralStudyAccess(studyInstanceUid, identityId),
    onSuccess: () => {
      queryClient.invalidateQueries([ASSIGNED_REFERRALS_LIST]);
    },
  });

  return { revokeReferralAccess, isUploading };
};

export default useRevokeReferralStudyAccess;

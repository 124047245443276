import React, { useState } from 'react';
import useSnackbar from '@core/hooks/useSnackbar';
import { ReferrerWithGroups } from 'app/features/groups/types/referrerWithGroups';
import useGetAssignedReferralsAndGroups from 'app/features/studies/hooks/useGetAssignedReferralsAndGroups';
import useAssignReferrer from '../../../hooks/useAssignReferrer';
import useRevokeReferralStudyAccess from '../../../hooks/useRevokeReferralStudyAccess';

const useFormState = ({
  isOpen,
  studyInstanceUid,
}: {
  isOpen: boolean;
  studyInstanceUid: string;
}) => {
  const { assignReferrerAsync } = useAssignReferrer();
  const { revokeReferralAccess } = useRevokeReferralStudyAccess();
  const showSnackbar = useSnackbar();
  const [selectedReferrer, setSelectedReferrer] = useState<ReferrerWithGroups | null>(null);
  const [selectedReferrerInput, setSelectedReferrerInput] = useState<ReferrerWithGroups | null>(
    null,
  );
  const { assignedReferralsAndGroups } = useGetAssignedReferralsAndGroups(
    studyInstanceUid || '',
    isOpen,
  );

  return {
    assignReferrerAsync,
    revokeReferralAccess,
    showSnackbar,
    assignedReferralsAndGroups,
    selectedReferrer,
    setSelectedReferrer,
    selectedReferrerInput,
    setSelectedReferrerInput,
  };
};

export default useFormState;

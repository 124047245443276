import ConstructionIcon from '@mui/icons-material/Construction';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CreditCardGear from '@assets/icons/CreditCardGear';
import GroupsIcon from '@assets/icons/Groups';
import Monitoring from '@assets/icons/Monitoring';

const getSettingsItems = () => [
  {
    key: 'users',
    label: 'Usuarios',
    route: 'users',
    enabled: true,
    icon: GroupIcon,
    sections: {
      dropdown: true,
      aside: true,
    },
    adminOnly: true,
    ownerOnly: false,
  },
  {
    key: 'groups',
    label: 'Grupos',
    route: 'groups',
    enabled: true,
    icon: GroupsIcon,
    sections: {
      dropdown: true,
      aside: true,
    },
    adminOnly: true,
    ownerOnly: false,
  },
  /* { 
    key: 'groups', 
    label: 'Grupos', 
    route: 'groups',
    enabled: true, 
    icon: GroupsIcon,
    sections: {
      dropdown: true,
      aside: true
    },
    adminOnly: true
  },
  { 
    key: 'account', 
    label: 'Cuenta',
    route: 'account',
    enabled: true, 
    icon: FolderOpenIcon,
    sections: {
      dropdown: true,
      aside: true
    },
    adminOnly: true
  }, */
  {
    key: 'permissions',
    label: 'Permisos',
    route: 'permissions',
    icon: RemoveRedEyeOutlinedIcon,
    enabled: true,
    adminOnly: true,
    ownerOnly: false,
    sections: {
      dropdown: true,
      aside: true,
    },
    subpaths: {
      '/permissions/add': true,
    } as Record<string, boolean>,
  },
  {
    key: 'subscription',
    label: 'Suscripción',
    route: 'subscription',
    enabled: true,
    icon: CreditCardGear,
    sections: {
      dropdown: true,
      aside: true,
    },
    adminOnly: true,
    ownerOnly: false,
  },
  {
    key: 'security',
    label: 'Seguridad',
    route: 'security',
    enabled: true,
    icon: LockIcon,
    sections: {
      dropdown: true,
      aside: true,
    },
    adminOnly: true,
    ownerOnly: false,
  },
  {
    key: 'settings',
    label: 'Personalizar',
    route: 'edit',
    enabled: true,
    icon: ConstructionIcon,
    sections: {
      dropdown: true,
      aside: true,
    },
    adminOnly: true,
    ownerOnly: false,
  },
  {
    key: 'reports',
    label: 'Reportes',
    route: 'reports',
    icon: Monitoring,
    enabled: true,
    adminOnly: true,
    ownerOnly: false,
    sections: {
      dropdown: true,
      aside: true,
    },
  },
];
export default getSettingsItems;

import { get } from '@core/api/baseApi';
import { ReferrerWithGroups } from '../types/referrerWithGroups';

export type GetReferrersWithGroupsFilters = {
  pageSize?: number;
  page?: number;
  searchTerm?: string;
};

const getReferrersWithGroups = (filters: GetReferrersWithGroupsFilters) =>
  get<ReferrerWithGroups[]>(`/Referrers/Groups`, {
    params: filters,
  });

export default getReferrersWithGroups;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GroupsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 11.84">
      <path d="M17.5,11.84v-1.63c0-.43-.05-.84-.16-1.23s-.27-.74-.49-1.08c.18-.03.37-.05.56-.06s.39-.01.59-.01c1.2,0,2.17.22,2.9.66s1.1,1.03,1.1,1.76v1.58h-4.5ZM18,6.84c-.55,0-1.02-.2-1.41-.59s-.59-.86-.59-1.41.2-1.04.59-1.43.86-.58,1.41-.58,1.04.19,1.43.58.58.86.58,1.43-.19,1.02-.58,1.41-.86.59-1.43.59Z" />
      <path d="M11,5.84c1.61,0,2.92-1.31,2.92-2.92s-1.31-2.92-2.92-2.92-2.92,1.31-2.92,2.92,1.31,2.92,2.92,2.92ZM8.81,7.1c-1.69.5-2.92,2.06-2.92,3.9,0,.37.3.68.68.68h8.87c.37,0,.68-.3.68-.68,0-1.85-1.23-3.41-2.92-3.9v1.16c.63.16,1.1.73,1.1,1.41v.91c0,.2-.16.37-.37.37h-.37c-.2,0-.37-.16-.37-.37s.16-.37.37-.37v-.55c0-.4-.33-.73-.73-.73s-.73.33-.73.73v.55c.2,0,.37.16.37.37s-.16.37-.37.37h-.37c-.2,0-.37-.16-.37-.37v-.91c0-.68.47-1.25,1.1-1.41v-1.3c-.14-.01-.28-.02-.42-.02h-2.09c-.14,0-.28,0-.42.02v1.49c.53.16.91.65.91,1.23,0,.71-.57,1.28-1.28,1.28s-1.28-.57-1.28-1.28c0-.58.39-1.07.91-1.23v-1.35ZM9.17,10.22c.3,0,.55-.24.55-.55s-.24-.55-.55-.55-.55.24-.55.55.24.55.55.55Z" />
      <path d="M0,11.84v-1.58c0-.72.37-1.3,1.1-1.75.73-.45,1.7-.68,2.9-.68.22,0,.43,0,.63.01s.39.03.58.06c-.23.35-.41.72-.53,1.1s-.18.78-.18,1.2v1.63H0ZM4,6.84c-.55,0-1.02-.2-1.41-.59s-.59-.86-.59-1.41.2-1.04.59-1.43.86-.58,1.41-.58,1.04.19,1.43.58.58.86.58,1.43-.19,1.02-.58,1.41-.86.59-1.43.59Z" />
    </SvgIcon>
  );
};

export default GroupsIcon;

import React, { useState } from 'react';
import TrashIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import ActionDialog from '@components/ActionDialog/ActionDialog';

type Props = {
  onConfirm: () => void;
};

const DeleteFolderAction = ({ onConfirm }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip
        onClick={(e) => {
          e.stopPropagation();
          setIsModalOpen(true);
        }}
        title="Eliminar carpeta"
      >
        <TrashIcon color="primary" sx={{ fontSize: '1.5rem' }} />
      </Tooltip>
      <ActionDialog
        open={isModalOpen}
        type="warning"
        onClose={() => {
          setIsModalOpen(false);
        }}
        withPropagationStopped
        onConfirm={onConfirm}
        title="Eliminar carpeta y contenido relacionado"
        message="¿Estás seguro de que deseas eliminar esta carpeta? Todos los elementos relacionados también serán eliminados. Esta acción no se puede deshacer."
      />
    </>
  );
};

export default DeleteFolderAction;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import RestrictToRole from '@components/RestrictToRole';
import Title from '@components/Title';
import useSnackbar from '@core/hooks/useSnackbar';
import { RoleName, TemplateFile } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import Actions from '../components/Actions';
import FolderView from '../components/FolderView';
import { FOLDER_ADD_ERROR_MESSAGES } from '../constants/folderErrorMessages';
import useEditFolder from '../hooks/useEditFolder';
import useTemplatesState from './useTemplatesState';

const Templates = () => {
  const { folders, folderLoading, filters } = useTemplatesState();
  const { editFolderAsync } = useEditFolder();
  const showSnackbar = useSnackbar();

  const navigate = useNavigate();

  const handleTemplateClick = (template: TemplateFile) => {
    navigate(`/templates/${template.folderHierarchyItemId}`);
  };

  return (
    <RestrictToRole
      allowedRoles={[RoleName.Radiologist, RoleName.System]}
      isAdminAllowed
      isOwnerAllowed
    >
      <Container sx={{ margin: 0 }} maxWidth={false} disableGutters>
        <Title text="Plantillas" />
        <Actions />
        <FolderView
          onEditFolder={async (templateFolderName, folder, folderPath) => {
            const response = await editFolderAsync({
              templateFolderId: folder.folderHierarchyItemId,
              templateFolderName,
              isOrganizationWide: filters.Section === 'Organization',
              folderPath,
            });
            if (isCustomErroHandlerResponse(response)) {
              showSnackbar({
                type: 'error',
                title: 'Error',
                message:
                  FOLDER_ADD_ERROR_MESSAGES[response.statusCode] ||
                  FOLDER_ADD_ERROR_MESSAGES.status,
              });
            }
          }}
          itemArray={folders?.root || {}}
          isLoadingFolders={folderLoading}
          onTemplateClick={handleTemplateClick}
          showPathOnFile={filters.Section === 'Recents'}
        />
      </Container>
    </RestrictToRole>
  );
};

export default Templates;

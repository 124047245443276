import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  breadcrumbsContainer: {
    display: 'flex',
    gap: '.5rem',
    flexWrap: 'wrap',
  },
  clickableBreadcrumb: {
    cursor: 'pointer',
  },
});

export default useSx;

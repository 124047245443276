import SxDef from '@core/types/SxDef';

const useSx = (): SxDef => ({
  root: {
    cursor: 'pointer',
    padding: 0,
    color: (theme) =>
      theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[500],
  },
});

export default useSx;

import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import If from '@components/If';
import {
  notificationStatusDTO,
  notificationStatusRequest,
} from '@core/constants/notificationStatus';
import useGetNotifications from '@core/hooks/useGetNotifications';
import useNotificationClick from '@core/hooks/useNotificationClick';
import useUpdateNotificationsStatus from '@core/hooks/useUpdateNotificationStatus';
import { NotificationMessage } from '@core/types';
import NotificationsMenu from './NotificationsMenu';
import useSx from './sx';

const circularProgressStyles = { width: '20px', height: '20px' };

const Notifications = () => {
  const navigate = useNavigate();
  const onNotificationClick = useNotificationClick();
  const [anchor, setAnchor] = useState<undefined | Element>(undefined);
  const { notifications, isLoading } = useGetNotifications(
    {
      filters: {
        Page: 1,
        PageSize: '5',
        SortOrder: 'DESC',
        IsSeen: notificationStatusRequest.unseen,
      },
    },
    true,
  );

  const { mutateAsync } = useUpdateNotificationsStatus();
  const sx = useSx();

  const handleNotificationsClick = (e: SyntheticEvent) => {
    setAnchor(e.currentTarget);
  };

  const onClose = async () => {
    setAnchor(undefined);
  };

  const onMenuItemClick = async (notificationMessage?: NotificationMessage) => {
    if (notificationMessage) {
      await onNotificationClick(notificationMessage);
      return;
    }

    onClose();
    navigate('/notifications');
  };

  const onCancelNotification = async (notificationMessage: NotificationMessage) => {
    const notificationToUpdate = {
      notificationMessageId: notificationMessage.notificationMessageId,
      isSeen: notificationStatusDTO.seen,
    };

    await mutateAsync({ notificationToUpdate: [notificationToUpdate] || [] });
  };
  const existsUnseenNotifications = notifications && notifications?.pageCount <= 0;

  return (
    <>
      <If condition={!isLoading} fallback={<CircularProgress style={circularProgressStyles} />}>
        <Tooltip title="Notificaciones">
          <Badge
            invisible={existsUnseenNotifications}
            badgeContent={notifications?.totalCount}
            color="error"
          >
            <NotificationsIcon sx={sx.root} onClick={handleNotificationsClick} />
          </Badge>
        </Tooltip>
      </If>
      <NotificationsMenu
        onClose={onClose}
        handleCancelNotification={onCancelNotification}
        onMenuItemClick={onMenuItemClick}
        anchorEl={anchor}
        notifications={notifications?.items || []}
      />
    </>
  );
};

export default Notifications;

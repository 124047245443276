import { post } from '@core/api/baseApi';
import { GetAssignedReferralsAndGroupsResponse } from '@core/types';

const assignReferrer = async (
  studyInstanceUid: string,
  identityId: string,
): Promise<GetAssignedReferralsAndGroupsResponse> =>
  await post(`/Studies/${studyInstanceUid}/authorized-referral/${identityId}`);

export default assignReferrer;

import { remove } from '@core/api/baseApi';
import { AssignRadiologistResponse } from '@core/types';

const revokeReferralStudyAccess = async (
  studyInstanceUid: string,
  identityId: string,
): Promise<AssignRadiologistResponse> =>
  await remove(`/Studies/${studyInstanceUid}/authorized-referral/${identityId}`);

export default revokeReferralStudyAccess;

import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import If from '@components/If';
import useSnackbar from '@core/hooks/useSnackbar';
import useStudyInfo from '@core/hooks/useStudyInfo';
import useWidth from '@core/hooks/useWidth';
import { EmailFormRequest } from '@core/types';
import useGetEditableReport from 'app/features/study/hooks/useGetEditableReport';
import useShareByEmail from 'app/features/study/hooks/useShareByEmail';
import useUploadReport from 'app/features/study/hooks/useUploadReport';
import Form from './Form';
import { ReportDocument } from './Form/documentTypes';

const Reports = () => {
  const [searchParams] = useSearchParams();
  const studyUid = searchParams.get('studyUid') || '';
  const SOPInstanceUID = searchParams.get('SOPInstanceUID') || '';
  const SeriesInstanceUID = searchParams.get('SeriesInstanceUID') || '';
  const width = useWidth();
  const deviceWidth = width === 'xs' ? 'sm' : width;
  const { uploadReport, isUploading } = useUploadReport(studyUid);
  const { share } = useShareByEmail(studyUid);
  const { studyInfo } = useStudyInfo({ studyInstanceUidOrHash: studyUid });
  const showSnackbar = useSnackbar();

  const { editableReport, isLoading } = useGetEditableReport({
    studyInstanceUid: studyUid,
    sopInstanceUid: SOPInstanceUID,
    enabled: !!SOPInstanceUID.length,
  });

  const handleShareByEmail = useCallback(async () => {
    if (!studyInfo?.patientsEmail || !studyInfo?.patientsFullName) {
      console.error('Patient email or name is undefined');
      return;
    }

    try {
      const req = {
        body: '',
        email: studyInfo.patientsEmail,
        name: studyInfo.patientsFullName,
      };

      await share(req as EmailFormRequest);
    } catch (error: any) {
      console.error(error);
    }
  }, [studyInfo, share]);

  async function uploadNewReport(report: ReportDocument) {
    try {
      const response = await uploadReport(report);

      if (response.success) {
        await handleShareByEmail();
        window?.opener?.onSuccessResponse?.();
        showSnackbar({
          type: 'success',
          title: 'Reporte creado',
          message: 'El reporte se ha creado.',
        });
        return;
      }

      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible crear el reporte.',
      });
    } catch (error) {
      console.log('Error', error);
    }
  }

  const handleReportSubmit = async (report: ReportDocument) => {
    if (!studyUid) return;

    // If there is SOPInstanceUID (Report already exists) updates the report
    if (SOPInstanceUID && SeriesInstanceUID)
      uploadNewReport({ ...report, savedDicomData: { SOPInstanceUID, SeriesInstanceUID } });
    else uploadNewReport(report);
  };

  const onCancel = () => {
    window?.opener?.onCancelReport();
  };

  return (
    <Container sx={{ height: '100%', paddingY: '20px' }} maxWidth={deviceWidth} disableGutters>
      <If condition={!isLoading || !SOPInstanceUID}>
        <Form
          studyId={studyUid ?? ''}
          encodedDocument={editableReport?.encodedDocument}
          initForm={{ name: editableReport?.title || '' }}
          handleCancel={onCancel}
          handleReportSubmit={handleReportSubmit}
          isLoading={isUploading}
        />
      </If>
    </Container>
  );
};

export default Reports;

import SxDef from '@core/types/SxDef';
import { pageConfig } from './Preview/Preview';

const paddingDifference = 20;
const scrollBarWith = 4;

const useSx = (): SxDef => ({
  container: {
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      width: `2px`,
    },
    '::-webkit-scrollbar-track': {
      background: '#777777',
    },

    '::-webkit-scrollbar-thumb': {
      background: '#555555',
    },

    '::-webkit-scrollbar-thumb:hover': {
      background: '#444444',
    },
  },
  root: {
    flex: 1,
    display: 'flex',
    gap: '20px',
    margin: 'auto',
    justifyContent: 'center',
    overflowX: 'auto',
    width: { xs: '100%', lg: 'min-content' },
    zoom: 0.82,
    '@media (min-width: 1600px)': {
      zoom: 1,
    },
    '@media (min-width: 1921px)': {
      zoom: 1.22,
    },
    '.texteditor-container': {
      flex: 1,
      minWidth: { xs: '100%', lg: pageConfig.size.width - paddingDifference * 2 },
      maxWidth: { xs: '100%', lg: pageConfig.size.width - paddingDifference * 2 },
      '.rooster': {
        '::-webkit-scrollbar': {
          width: `${scrollBarWith}px`,
        },
        paddingY: '1rem',
        scrollbarGutter: 'stable',
        paddingX: `${pageConfig.padding.left - paddingDifference - scrollBarWith / 2}px`,
      },
    },
    '.preview-container': { display: { xs: 'none', lg: 'block' } },
  },
});

export default useSx;

export const FOLDER_ADD_ERROR_MESSAGES: Record<string, string> = {
  401: 'No tienes permiso para agregar carpetas con los requisitos solicitados.',
  404: 'Carpeta no encontrada.',
  400: 'La solicitud contiene uno o más errores. Por favor, revisa los datos e inténtalo de nuevo.',
  default: 'Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.',
};

export const FOLDER_DELETE_ERROR_MESSAGES: Record<string, string> = {
  401: 'No tienes permiso para eliminar carpetas con los requisitos solicitados.',
  403: 'No tienes permiso para eliminar carpetas con los requisitos solicitados.',
  404: 'Carpeta no encontrada.',
  400: 'La solicitud contiene uno o más errores. Por favor, revisa los datos e inténtalo de nuevo.',
  default: 'Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.',
};

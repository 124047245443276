import React from 'react';
import Box from '@mui/material/Box';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import userItemMapper from '@core/utils/userToUserItemMapper';
import AssignedReferrers from '../AssignedReferrers';
import ReferrersAndGroupsAutocomplete from '../ReferrersAndGroupsAutocomplete';
import { FormPropsWithNotification } from './FormProps';
import useFormState from './useFormState';

const Form = ({
  open = false,
  onClose,
  studyInstanceUid,
  handleSendNotification,
  isSendingNotification,
}: FormPropsWithNotification) => {
  const {
    assignReferrerAsync,
    revokeReferralAccess,
    showSnackbar,
    assignedReferralsAndGroups,
    selectedReferrer,
    setSelectedReferrer,
    selectedReferrerInput,
    setSelectedReferrerInput,
  } = useFormState({ isOpen: open, studyInstanceUid });
  const isSubmittingLoading = isSendingNotification;

  const handleAssignReferrer = async () => {
    const response = await assignReferrerAsync({
      studyInstanceUid: studyInstanceUid || '',
      identityId: selectedReferrerInput?.identityId || '',
    });

    if (!isCustomErroHandlerResponse(response)) {
      const selectedReferrerItem = userItemMapper({
        userUid: selectedReferrerInput?.userUid || '',
        givenName: selectedReferrerInput?.referrerGivenName || '',
        surname: selectedReferrerInput?.referrerSurname || '',
      });
      await handleSendNotification(studyInstanceUid, selectedReferrerItem);
      showSnackbar({
        message: 'Médico referente asignado con éxito.',
        title: 'Médico asignado',
        type: 'success',
      });
      return;
    }

    showSnackbar({
      message: 'No fue posible asignar al médico referente.',
      title: 'Ocurrió un error',
      type: 'error',
    });
  };

  const revokeUserAccess = async () => {
    const response = await revokeReferralAccess({
      studyInstanceUid: studyInstanceUid || '',
      identityId: selectedReferrer?.identityId || '',
    });

    if (!isCustomErroHandlerResponse(response)) {
      showSnackbar({
        message: 'Acceso revocado al estudio médico.',
        type: 'success',
        title: 'Acceso cancelado',
      });
      return;
    }

    showSnackbar({
      message: 'No se pudo revocar acceso al estudio médico. Por favor, intenta de nuevo.',
      type: 'error',
      title: 'Ocurrió un error',
    });
  };

  const handleSubmit = async () => {
    if (!selectedReferrerInput) return;

    await handleAssignReferrer();
  };

  const handleRevokeAccess = async () => {
    if (!selectedReferrer) return;
    await revokeUserAccess();

    setSelectedReferrerInput(null);
    setSelectedReferrer(null);
  };

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <AssignedReferrers
        assignedReferralsAndGroups={assignedReferralsAndGroups}
        onChipClick={(referrer) => {
          if (selectedReferrer?.userUid === referrer?.userUid) {
            setSelectedReferrer(null);
            return;
          }

          setSelectedReferrer(referrer);
        }}
        isActive={(referrer) => selectedReferrer?.userUid === referrer?.userUid}
      />
      <ReferrersAndGroupsAutocomplete
        filteredIds={new Set(assignedReferralsAndGroups?.authorizedUsers.map((u) => u.identityId))}
        onReferrerOrGroupSelect={(referrerOrGroup) => {
          setSelectedReferrerInput(referrerOrGroup);
        }}
        placeholder="Seleccionar médico referente"
      />
      <Box display="flex" justifyContent="flex-end" gap="10px">
        <If condition={!!selectedReferrer}>
          <ActionButton
            text="Revocar Accesso"
            variant="contained"
            color="error"
            sx={{ marginRight: 'auto' }}
            onClick={handleRevokeAccess}
          />
        </If>
        <ActionButton variant="outlined" color="secondary" onClick={onClose} text="Cancelar" />
        <ActionButton
          text="Asignar Estudio"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          isLoading={isSubmittingLoading}
        />
      </Box>
    </Box>
  );
};

export default Form;

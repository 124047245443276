import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import AssignedReferrersProps from './AssignedReferrersProps';
import useSx from './sx';

const AssignedRefferals = ({
  assignedReferralsAndGroups,
  onChipClick,
  isActive,
}: AssignedReferrersProps) => {
  const sx = useSx();
  return (
    <Box display="flex" alignItems="center" gap="5px" flexWrap="wrap">
      {assignedReferralsAndGroups?.authorizedUsers.map((referrer) => (
        <Chip
          onClick={() => {
            onChipClick(referrer);
          }}
          key={referrer.userUid || referrer.referrerGroupId}
          label={referrer.referrerFullName || referrer.referrerGroupName}
          sx={isActive(referrer) ? sx.chipActive : sx.chip}
        />
      ))}
    </Box>
  );
};

export default AssignedRefferals;

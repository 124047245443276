import { useMutation, useQueryClient } from '@tanstack/react-query';
import { remove } from '@core/api/baseApi';
import { GET_FOLDERS_QUERY_KEY } from './useGetFolders';

export const removeFolder = (templateFolderId: number): Promise<void> => {
  return remove(`/ReportTemplates/folder/${templateFolderId}`);
};

const useDeleteFolder = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteFolderAsync, ...rest } = useMutation({
    mutationFn: (templateFolderId: number) => removeFolder(templateFolderId),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_FOLDERS_QUERY_KEY]);
    },
  });

  return { ...rest, deleteFolderAsync };
};

export default useDeleteFolder;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function nameTime(hh: number, mm: number): string {
  const hours = hh === 0 ? 12 : hh > 12 ? hh - 12 : hh;
  const minutes = mm < 10 ? `0${mm}` : mm;

  return `${hours}:${minutes} ${hh < 12 ? 'AM' : 'PM'}`;
}

export function nameDate(d: string): string {
  const date = dayjs.utc(d).local();
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');

  if (date.isSame(today, 'day')) {
    return nameTime(date.hour(), date.minute());
  }

  if (date.isSame(yesterday, 'day')) {
    return 'Ayer';
  }

  return date.format('D MMM YYYY');
}

export function formatDate(studyDate?: string, options?: { full: boolean }): string {
  if (!studyDate || studyDate.length === 0) {
    return 'No disponible';
  }

  const { full } = options ?? {};
  const date = dayjs.utc(studyDate).local();

  if (full) {
    return date.format('D MMM YYYY');
  }

  if (date.isSame(dayjs(), 'day')) {
    return nameTime(date.hour(), date.minute());
  }

  if (date.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return 'Ayer';
  }

  return date.format('D MMM YYYY');
}

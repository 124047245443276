import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (sidebar: boolean, sidebarCollapsed: boolean): SxDef => ({
  root: {
    '.main-container': {
      boxSizing: 'border-box',
      maxWidth: '100vw',
      marginLeft: { xs: 0, sm: sidebarCollapsed ? '70px' : '200px' },
      padding: {
        xs: '1rem',
        sm: '2rem',
      },
      flex: 1,
      ...expandDisplayFlex({ d: 'column' }),
      transition: 'all 0.3s',
      '@media (min-width:1921px)': {
        maxWidth: '1918px',
        margin: '0 auto',
        paddingRight: '1rem',
        //Applied padding to fix centering of content
        paddingLeft: `calc(1rem + ${sidebarCollapsed ? '70px' : '200px'})`,
      },

      '@media (min-width:1980px)': {
        maxWidth: '1980px',
        paddingRight: '0',
        paddingLeft: `calc(0px + ${sidebarCollapsed ? '70px' : '200px'})`,
      },
    },
    '.main-drawer-container': {
      display: { xs: 'block', sm: sidebar ? 'block' : 'none' },
      height: 'calc(100% - 64px)',
      position: 'fixed',
      top: '64px',
      width: {
        xs: '0',
        sm: sidebarCollapsed ? '70px' : '200px',
      },
      transition: 'width 0.3s',
      backgroundColor: (theme) => theme.palette.primary.main,
    },
  },
});
export default useSx;

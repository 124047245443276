import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { Editor } from 'roosterjs-editor-core';
import sanitizeHtml from 'sanitize-html';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ActionButton from '@components/Button/ActionButton';
import FullScreenLoader from '@components/FullScreenLoader';
import If from '@components/If';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useProfile from '@core/hooks/useProfile';
import RecordAudioAction from 'app/features/reports/add/components/Form/RecordAudioAction';
import TranslatorButton from 'app/features/reports/add/components/Form/TranslatorButton';
import UploadAudioAction from 'app/features/reports/add/components/Form/UploadAudioAction';
import {
  initialSignature,
  initialTemplate,
} from 'app/features/reports/add/constants/initialTemplate';
import FormDialogProps, { InitialValues } from './FormDialogProps';
import PreviewTextEditor from './PreviewTextEditor';
import TemplateTagDrawer from './TemplateTagDrawer';
import useSx from './sx';

const FormDialog = ({
  onDelete,
  onAddNew,
  onSave,
  initialValues,
  canUserEditTemplate,
  title,
}: FormDialogProps) => {
  const sx = useSx();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const editor = useRef<Editor>(null);
  const navigate = useNavigate();
  const { hasAdminPermissions } = useProfile();
  const formVersion = !!initialValues.content ? 'update' : 'add';
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isTemplateTagsDrawerOpen, setIsTemplateTagsDrawerOpen] = useState(false);

  const defaultSubmit = () => {};

  async function validateEditorContent(
    editorProp: RefObject<Editor>,
    validateForm: (values?: any) => Promise<FormikErrors<InitialValues>>,
    setErrors: (errors: FormikErrors<InitialValues>) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<InitialValues>>,
  ) {
    const content = editorProp.current?.getContent();
    if (content !== undefined) {
      const sanitizedHTML = sanitizeHtml(content, {
        allowedTags: [],
      });

      const newErrors = await validateForm();

      if (sanitizedHTML.length < 1) {
        setErrors({ ...newErrors, content: 'Por favor introduzca el contenido' });
        throw new Error('Invalid content');
      }

      await setFieldValue('content', content);
      return content;
    }
  }
  const handleUpdateTextEditorContent = async (newContent: string) => {
    if (editor.current && newContent) {
      try {
        editor.current?.setContent(newContent);
      } catch (error) {
        console.error('Error updating editor content:', error);
      }
    }
  };

  useEffect(() => {
    const button = document.getElementById('left-drawer-close-button');
    if (button) {
      button.click();
    }
  }, []);

  useEffect(() => {
    function setInitialTemplate() {
      const currentContent = editor.current?.getContent() || '';
      const newContent = initialTemplate.concat(currentContent);
      editor.current?.setContent(newContent);
    }
    function addSignature() {
      const html = editor.current?.getContent();
      editor.current?.setContent(html + initialSignature);
    }

    const ref = subscribe('evodicom.report.initialTemplate', setInitialTemplate);
    const ref2 = subscribe('evodicom.report.insertQrCode', addSignature);

    return () => {
      unsubscribe('evodicom.report.initialTemplate', ref);
      unsubscribe('evodicom.report.insertQrCode', ref2);
    };
    // It is not necessary to include the initialTemplate in the dependencies array
    // since it is a constant value and it will not change once it is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  const selection = useRef<Range | null>(null);

  const saveSelection = () => {
    selection.current = editor.current?.getSelectionRange() || null;
  };

  const handleTemplateTagSelect = (
    value: { templateTagId: number; name: string },
    tagElementGeneator: (tag: { templateTagId: number; name: string }) => HTMLElement,
  ) => {
    if (!editor.current && !selection.current) return;
    const newTemplateTagSpan = tagElementGeneator(value);
    editor.current?.insertNode(newTemplateTagSpan, {
      range: selection.current!,
      position: 5,
    });
    setIsTemplateTagsDrawerOpen(false);
  };

  const handleTemplateSanitizer = (form: InitialValues) => {
    // Fix for the issue where there the editor add a template tag, edits it and then deletes the content
    // So it needs to be sanitized
    const content = form.content;
    const sanitizedDiv = document.createElement('div');
    sanitizedDiv.innerHTML = content;

    const templateTags = sanitizedDiv.querySelectorAll('span[class');

    templateTags.forEach((tag) => {
      if (!tag.textContent) {
        tag.className = '';
        tag.removeAttribute('class');
      }
    });

    onSave?.({ ...form, content: sanitizedDiv.innerHTML });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      onSubmit={handleTemplateSanitizer || defaultSubmit}
    >
      {({ values, handleSubmit, setFieldValue, errors, resetForm, setErrors, validateForm }) => (
        <Box onSubmit={handleSubmit} sx={sx.root} className="form" component="form">
          <Box sx={sx.tabsContainer}>
            <Tabs value={'no-value'} onChange={() => {}}>
              <Tab className="tab" value="no-value" label={title} />
            </Tabs>
            <If condition={hasAdminPermissions && canUserEditTemplate}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.organizationWide}
                    onChange={(e) => {
                      setFieldValue('organizationWide', e.target.checked);
                    }}
                  />
                }
                label="Mostrar a toda la organización"
              />
            </If>
            <If condition={canUserEditTemplate}>
              <ActionButton
                text="Seleccionar etiquetas"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsTemplateTagsDrawerOpen(true);
                }}
              />
            </If>
            <If condition={!!onDelete && canUserEditTemplate}>
              <ActionButton
                text="Eliminar"
                variant="contained"
                color="error"
                onClick={onDelete}
                icon={<DeleteOutlineOutlinedIcon />}
              />
            </If>
            <If condition={canUserEditTemplate}>
              <ActionButton
                text="Cancelar"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetForm();
                  navigate('/templates');
                }}
              />
            </If>
            <If condition={formVersion === 'update'}>
              <ActionButton
                text="Guardar como nuevo"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  validateEditorContent(editor, validateForm, setErrors, setFieldValue)
                    .then((content) => {
                      if (content) onAddNew?.({ ...values, content });
                    })
                    .catch(console.error);
                }}
              />
            </If>
            <ActionButton
              text="Guardar"
              variant="contained"
              color="primary"
              onClick={() => {
                validateEditorContent(editor, validateForm, setErrors, setFieldValue)
                  .then(() => {
                    formikRef.current?.handleSubmit();
                  })
                  .catch(console.error);
              }}
            />
          </Box>
          <TemplateTagDrawer
            isOpen={isTemplateTagsDrawerOpen}
            onClose={() => {
              setIsTemplateTagsDrawerOpen(false);
            }}
            handleTemplateTagSelect={handleTemplateTagSelect}
          />
          <PreviewTextEditor
            initialContent={values.content}
            textEditorRef={editor}
            error={!!errors.content}
            helperText={errors.content}
            onTextEditorBlur={saveSelection}
          />
          <Box sx={sx.footerButtons}></Box>
          <TranslatorButton textEditorRef={editor} isPreviewEditor={true} />
          <If condition={isTranscribing}>
            <FullScreenLoader transparent />
          </If>
          <RecordAudioAction
            onUpdateTextEditor={handleUpdateTextEditorContent}
            isTranscribing={isTranscribing}
            setIsTranscribing={setIsTranscribing}
          />
          <UploadAudioAction
            onUpdateTextEditor={handleUpdateTextEditorContent}
            isTranscribing={isTranscribing}
            setIsTranscribing={setIsTranscribing}
          />
        </Box>
      )}
    </Formik>
  );
};

export default FormDialog;

import React, { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Typography } from '@mui/material';
import If from '@components/If';
import { TemplateFile, TemplateFolder } from '@core/types';
import FolderInput from '../FolderInput';
import useSx from '../FolderItem/sx';

interface NewFolderFormProps {
  currentFolder: { [key: string]: TemplateFile | TemplateFolder };
  onClose: () => void;
  onSubmit: (folderName: string, setError: (error: string) => void) => void;
}

export default function NewFolderForm({ currentFolder, onClose, onSubmit }: NewFolderFormProps) {
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  // This component uses two diferent styles
  // - Ones from sx to match the styles from FolderItem
  // - Inline overrides: These are specific to this component.
  const sx = useSx();

  function handleAddNewFolder() {
    onSubmit(inputRef.current?.value || '', setError);
  }
  const getFolderName = (folder: { [key: string]: TemplateFile | TemplateFolder }) => {
    let counter = 1;
    const placeholder = 'Nueva Carpeta';
    let FOLDER_NAME = placeholder;
    const existingFolderNames = Object.values(folder)
      .filter((s) => !s.isFile)
      .map((s) => s.name);
    const uniqueFolderNames = new Set(existingFolderNames);
    while (uniqueFolderNames.has(FOLDER_NAME)) {
      FOLDER_NAME = placeholder + ` ${counter}`;
      counter += 1;
    }
    return FOLDER_NAME;
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [currentFolder]);

  return (
    <Box sx={sx.root}>
      <FolderIcon sx={sx.coloredIcon} />
      <Box sx={{ flex: '1' }}>
        <FolderInput
          onNewFolderNameAccepted={handleAddNewFolder}
          initialValue={getFolderName(currentFolder)}
          inputRef={inputRef}
          fullWidth
        />
        <If condition={error !== ''}>
          <Typography
            component="p"
            sx={{
              color: (theme) => theme.palette.error.main,
              fontFamily:
                'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
              fontWeight: 400,
              fontSize: '0.65625rem',
              lineHeight: '1.66',
              textAlign: 'left',
              marginTop: '3px',
              marginRigt: '14px',
              marginBottom: 0,
              marginLeft: '14px',
            }}
          >
            {error}
          </Typography>
        </If>
      </Box>
      <Close onClick={onClose} sx={{ color: 'red', fontSize: '1.75rem' }} />
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import { GetFoldersResponse, TemplateFile, TemplateFolder } from '@core/types';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import {
  FOLDER_ADD_ERROR_MESSAGES,
  FOLDER_DELETE_ERROR_MESSAGES,
} from '../../constants/folderErrorMessages';
import useAddFolder from '../../hooks/useAddFolder';
import useDeleteFolder from '../../hooks/useDeleteFolder';
import Breadcrumbs from './Breadcrumbs';
import FolderItem from './FolderItem';
import NewFolderForm from './NewFolderForm';
import useSx from './sx';

dayjs.extend(utc);

interface FolderViewProps {
  itemArray: GetFoldersResponse['root'];
  initialPath?: TemplateFolder['folderHierarchyItemId'][];
  onTemplateClick?: (template: TemplateFile) => void;
  onFolderNavigation?: (folder: TemplateFolder, folderPath: number[]) => void;
  onEditFolder?: (newTemplateName: string, item: TemplateFolder, folderPath: number[]) => void;
  smallView?: boolean;
  enableLocalSearch?: boolean;
  isLoadingFolders: boolean;
  enableCreateFolder?: boolean;
  isOrganizationWide?: boolean;
  showPathOnFile?: boolean;
}

export default function FolderView({
  itemArray,
  onTemplateClick = () => {},
  onFolderNavigation = () => {},
  initialPath = [],
  onEditFolder,
  isLoadingFolders,
  smallView = false,
  enableLocalSearch = false,
  enableCreateFolder = false,
  isOrganizationWide = false,
  showPathOnFile = false,
}: FolderViewProps) {
  const ROOT_BREADCRUMB: TemplateFolder = {
    folderHierarchyItemId: 0,
    isFile: false,
    name: 'Plantillas',
    ownerUserUid: true,
    organizationWide: true,
    modifiedDate: '',
    subItems: itemArray,
  };
  const { hasAdminPermissions, userUid } = useProfile();
  const showSnackbar = useSnackbar();
  const { addFolderAsync } = useAddFolder();
  const [currentFolder, setCurrentFolder] = useState<TemplateFolder['subItems']>(itemArray);
  const [breadcrumbs, setBreadcrumbs] = useState<TemplateFolder[]>([ROOT_BREADCRUMB]);
  const [localFilter, setLocalFilter] = useState<string>('');
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const sx = useSx();

  function navigateFolder(folder: TemplateFolder) {
    const newBreadcrumbs = [...breadcrumbs, folder];

    const folderPath = newBreadcrumbs
      .filter((s) => s.folderHierarchyItemId !== 0)
      .map((breadcrumb) => breadcrumb.folderHierarchyItemId);
    setBreadcrumbs([...breadcrumbs, folder]);
    setCurrentFolder(folder.subItems);
    setOpenCreateFolder(false);

    onFolderNavigation(folder, folderPath);
  }

  function breadcrumbClick(folder: TemplateFolder, index: number) {
    const newBreadcrumbs = breadcrumbs.splice(0, index + 1);

    setBreadcrumbs(newBreadcrumbs);
    setCurrentFolder(folder.subItems);
    setOpenCreateFolder(false);

    const folderPath = newBreadcrumbs
      .filter((s) => s.folderHierarchyItemId !== 0)
      .map((breadcrumb) => breadcrumb.folderHierarchyItemId);
    onFolderNavigation(folder, folderPath);
  }

  function handleLocalFilter(element: TemplateFile | TemplateFolder) {
    return !element.name.toLocaleLowerCase().includes(localFilter.toLocaleLowerCase());
  }

  async function handleCreateFolder(folderName: string, setError: (error: string) => void) {
    if (folderName === '') {
      setError('El nombre de la carpeta no puede estar vacio');
      return;
    }
    const existingFolders = Object.values(currentFolder).filter((node) => !node.isFile);
    if (existingFolders.find((folder) => folder.name === folderName)) {
      setError('Ya existe una carpeta con este nombre');
      return;
    }

    const folderPath = breadcrumbs
      .filter((s) => s.folderHierarchyItemId !== 0)
      .map((breadcrumb) => breadcrumb.folderHierarchyItemId);

    const response = await addFolderAsync({
      folderName: folderName,
      folderPath,
      isOrganizationWide,
    });
    if (isCustomErroHandlerResponse(response)) {
      showSnackbar({
        type: 'error',
        message:
          FOLDER_ADD_ERROR_MESSAGES[response.statusCode] || FOLDER_ADD_ERROR_MESSAGES.default,
        title: 'Error',
      });
    } else {
      setOpenCreateFolder(false);
    }
  }

  const filteredElements = Object.values(currentFolder).filter((item) => {
    return enableLocalSearch ? !handleLocalFilter(item) : true;
  });

  useEffect(() => {
    // Next block of code is used to navigate to the folder indicated on the initial path
    let INITIAL_FOLDER = itemArray;
    let INITIAL_BREADCRUMBS = [ROOT_BREADCRUMB];

    const folderIds = [...initialPath];

    while (folderIds.length > 0) {
      const folderId = folderIds.shift();
      const foundFolder = INITIAL_FOLDER[`folder-${folderId}`];

      if (foundFolder && foundFolder.subItems) {
        INITIAL_FOLDER = foundFolder.subItems;
        INITIAL_BREADCRUMBS = [...INITIAL_BREADCRUMBS, foundFolder];
      } else {
        break;
      }
    }

    setBreadcrumbs(INITIAL_BREADCRUMBS);
    setCurrentFolder(INITIAL_FOLDER);
  }, [itemArray]);

  const onFolderEdit = (newTemplateName: string, selectedFolder: TemplateFolder) => {
    const folderPath = breadcrumbs
      .filter((s) => s.folderHierarchyItemId !== 0)
      .map((breadcrumb) => breadcrumb.folderHierarchyItemId);

    onEditFolder?.(newTemplateName, selectedFolder, folderPath);
  };

  const { deleteFolderAsync } = useDeleteFolder();

  const handleDeleteFolder = async (folderItem: TemplateFolder) => {
    const response = await deleteFolderAsync(folderItem.folderHierarchyItemId);
    if (isCustomErroHandlerResponse(response)) {
      showSnackbar({
        type: 'error',
        message:
          FOLDER_DELETE_ERROR_MESSAGES[response.statusCode] || FOLDER_DELETE_ERROR_MESSAGES.default,
        title: 'Error',
      });
    }
  };

  const canDeleteFolder = (el: TemplateFolder | TemplateFile) => {
    const isFolder = !el.isFile;

    if (!isFolder) return false;

    const isPrivate = !!el.ownerUserUid;

    const isOwner = el.ownerUserUid === userUid;

    if (isPrivate && isOwner) return true;

    if (hasAdminPermissions && !isPrivate) return true;

    return false;
  };

  return (
    <Box sx={sx.card}>
      <Box sx={sx.commandsContainer}>
        <Breadcrumbs breadcrumbs={breadcrumbs} onBreadcrumbClick={breadcrumbClick} />
        <If condition={enableLocalSearch}>
          <Input
            placeholder="Buscar"
            onChange={(e) => {
              setLocalFilter(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            sx={sx.searchInput}
          />
        </If>
      </Box>
      <If condition={enableCreateFolder && !openCreateFolder}>
        <ActionButton
          text="Nueva carpeta"
          variant="outlined"
          color="secondary"
          onClick={() => setOpenCreateFolder(true)}
          sx={{ alignSelf: 'flex-end' }}
        />
      </If>
      <Box sx={smallView ? sx.foldersViewSmall : sx.foldersView}>
        <If
          condition={!isLoadingFolders}
          fallback={
            <Box sx={sx.rowContent}>
              <Typography sx={sx.templateName}>Cargando...</Typography>
            </Box>
          }
        >
          <If condition={enableCreateFolder && openCreateFolder}>
            <NewFolderForm
              currentFolder={currentFolder}
              onClose={() => setOpenCreateFolder(false)}
              onSubmit={handleCreateFolder}
            />
          </If>
          <If
            condition={filteredElements.length > 0}
            fallback={
              <Box sx={sx.rowContent}>
                <Typography sx={sx.templateName}>
                  No se ha encontrado ningún archivo con ese nombre dentro de esta carpeta
                </Typography>
              </Box>
            }
          >
            {filteredElements.map((el) => (
              <FolderItem
                key={el.folderHierarchyItemId + el.modifiedDate + el.isFile.toString()}
                folderItem={el}
                onTemplateClick={onTemplateClick}
                onFolderClick={navigateFolder}
                onFolderEdit={onFolderEdit}
                onFolderDelete={handleDeleteFolder}
                showPathOnFile={showPathOnFile}
                canDeleteFolder={canDeleteFolder(el)}
              />
            ))}
          </If>
        </If>
      </Box>
    </Box>
  );
}

import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { SxProps, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import useGetReferrersWithGroups from 'app/features/groups/hooks/useGetReferrersWithGroups';
import { ReferrerWithGroups } from 'app/features/groups/types/referrerWithGroups';
import useSx from './sx';

interface ReferrersAndGroupsAutocompleteProps {
  onReferrerOrGroupSelect: (value: ReferrerWithGroups | null) => void;
  placeholder: string;
  textFieldProps?: TextFieldProps;
  hideSelectedOption?: boolean;
  filteredIds?: Set<string>;
}

const ReferrersAndGroupsAutocomplete: React.FC<ReferrersAndGroupsAutocompleteProps> = ({
  onReferrerOrGroupSelect,
  placeholder,
  textFieldProps,
  hideSelectedOption = false,
  filteredIds,
}) => {
  const sx = useSx();
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<ReferrerWithGroups | undefined>(undefined);
  const [filters, setFilters] = useState({
    searchTerm: '',
    SortOrder: 'ASC',
    page: 1,
    pageSize: 10,
  });

  const { response, isLoading } = useGetReferrersWithGroups(filters);

  const { sx: outerTextfieldSx, ...restTextFieldProps } = textFieldProps || ({} as TextFieldProps);
  const combinedSxTextfield = { ...sx.textfield, ...outerTextfieldSx } as SxProps<Theme>;

  const handleTemplateChange = (value: string): void => {
    setFilters((prev) => ({
      ...prev,
      searchTerm: value,
    }));
  };

  const handleChangeDebounced = useMemo(
    () => debounce(handleTemplateChange, 300),
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onInputChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = ($e) => {
    setInputValue($e.target.value); // Update inputValue on input change
    handleChangeDebounced($e.target.value);
  };

  const onUserSelectHandler = (
    _: React.SyntheticEvent<Element, Event>,
    user: ReferrerWithGroups | null,
  ) => {
    onReferrerOrGroupSelect(user);
    if (hideSelectedOption) {
      setSelectedUser(undefined);
      setInputValue(''); // Clear the input when an option is selected
    } else {
      setSelectedUser(user || undefined);
      setInputValue(user?.referrerGroupName || user?.referrerFullName || ''); // Set input to user's full name when selected
    }
  };

  const filteredUsers = filteredIds
    ? response?.filter((u) => !filteredIds.has(u.identityId!))
    : response;

  return (
    <Autocomplete
      options={filteredUsers || []}
      renderOption={(props, option) => (
        <li {...props} key={option.userUid || option.referrerGroupId}>
          {option.referrerFullName || option.referrerGroupName}
        </li>
      )}
      getOptionLabel={(option) => option.referrerFullName || option.referrerGroupName || ''}
      loading={isLoading}
      isOptionEqualToValue={(option, value) =>
        (!!value.userUid && option.userUid === value.userUid) ||
        (!!option.referrerGroupId && option.referrerGroupId === value.referrerGroupId)
      }
      onChange={onUserSelectHandler}
      inputValue={inputValue} // Conditionally control the selected value to avoid highlighting
      value={hideSelectedOption ? undefined : selectedUser} // Conditionally control the selected value to avoid highlighting
      disableClearable={hideSelectedOption} // Disable the clear (x) button
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onInputChange}
          fullWidth
          sx={combinedSxTextfield}
          hiddenLabel
          placeholder={placeholder}
          {...restTextFieldProps}
        />
      )}
    />
  );
};

export default ReferrersAndGroupsAutocomplete;

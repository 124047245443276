import useFilters from '../hooks/useFilters';
import useGetFolders from '../hooks/useGetFolders';
export type Tsections = 'recientes' | 'organización' | 'privado';

const useTemplatesState = () => {
  const [filters, setFilters, removeFilter] = useFilters();
  const { folders, isLoading: folderLoading } = useGetFolders(filters);

  return {
    folders,
    folderLoading,
    filters,
    setFilters,
    removeFilter,
  };
};

export default useTemplatesState;

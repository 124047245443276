import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Template } from '@core/types';
import ActionDialog from '../../../../../../components/ActionDialog/ActionDialog';
import TemplateDropdownProps from './TemplateDropdownProps';
import useSx from './sx';
import useTemplateDropdownState from './useTemplateDropdownState';

const zIndexForComponents = { sx: { zIndex: 100000 } };

const componentsProps = {
  popupIndicator: zIndexForComponents,
  paper: zIndexForComponents,
  popper: zIndexForComponents,
  clearIndicator: zIndexForComponents,
};

const seeMoreOption = {
  templateId: 0,
  templateName: 'Buscar plantillas',
  content: 'Content 0',
  organizationWide: false,
  ownerUserUid: '0',
  modifiedDate: new Date(),
  folderName: '',
};

const TemplateDropdown = ({ onConfirm, onCancel, onSeeMoreTemplates }: TemplateDropdownProps) => {
  const [filters, setFilters] = useState({
    Page: '1',
    Search: '',
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const sx = useSx();
  const { templates, isLoading } = useTemplateDropdownState(filters);

  const [selectedTemplate, setSelectedTemplate] = useState('');

  const templateOptions = useMemo(() => {
    if (templates?.items) {
      return [...templates.items, seeMoreOption];
    }

    return [];
  }, [templates?.items]);

  const onTemplateChange = (_: React.SyntheticEvent<Element, Event>, template: Template | null) => {
    if (template?.templateId === 0) {
      onSeeMoreTemplates();
      return;
    }

    setSelectedTemplate(template?.templateId.toString() || '');
    setDialogOpen(true);
  };

  const handleTemplateChange = (value: string): void => {
    setFilters((prev) => ({
      ...prev,
      Search: value,
    }));
  };

  // Función debounced creada fuera del componente
  const handleChangeDebounced = useCallback(debounce(handleTemplateChange, 1000), []);

  const onConfirmTemplate = () => {
    onConfirm(selectedTemplate);
    setDialogOpen(false);
    setSelectedTemplate('');
  };

  const onCancelCallback = () => {
    onCancel();
    setSelectedTemplate('');
    setDialogOpen(false);
  };

  return (
    <>
      <ActionDialog
        open={dialogOpen}
        message="Al hacer clic en 'Confirmar', se eliminará el contenido actual y se actualizará con la plantilla seleccionada."
        title="Actualizar Reporte"
        onClose={onCancelCallback}
        onConfirm={onConfirmTemplate}
        type="warning"
      />
      <Autocomplete
        fullWidth
        clearOnBlur
        blurOnSelect
        clearOnEscape
        componentsProps={componentsProps}
        value={null}
        filterOptions={(x) => x}
        options={templateOptions}
        getOptionLabel={(option) => option.templateName}
        renderOption={(props, value) => (
          <Typography {...props} component="li" key={value.templateId} sx={sx.list}>
            {value.templateName}
            <Typography component="span" sx={sx.category}>
              {value.folderName}
            </Typography>
          </Typography>
        )}
        loading={isLoading}
        onChange={onTemplateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Plantillas"
            onChange={($e) => {
              handleChangeDebounced($e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={sx.templateDropdown}
          />
        )}
      />
    </>
  );
};

export default TemplateDropdown;
